import * as React from "react";
import AppContext from "../../context/AppContext";

const MenuDrawerDesign = () => {
    return (
        <AppContext.Consumer>
            {(appState) => {
                return (
                    <div
                        className={`menu-drawer-container ${
                            appState.isMenuOpen ? "is-open" : ""
                        }`}
                    >
                        <div className="menu-drawer-wrap">
                            <div className="menu-actions">
                                <button
                                    className="close-menu-button"
                                    onClick={appState.toggleMenu}
                                >
                                    CLOSE MENU
                                    <span></span>
                                    <span></span>
                                </button>
                            </div>
                            <div className="menu-content">
                                <ul className="menu-items-list">
                                    <li>
                                        <a
                                            href="/"
                                            onClick={appState.toggleMenu}
                                        >
                                            BACK TO HOME
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#frame">FRAME</a>
                                    </li>
                                    <li>
                                        <a href="#handlebar">HANDLEBAR</a>
                                    </li>
                                    <li>
                                        <a href="#tyre">TYRE</a>
                                    </li>
                                    <li>
                                        <a href="#gear-set">GEAR SET</a>
                                    </li>
                                    <li>
                                        <a href="#center-stand">CENTER STAND</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                );
            }}
        </AppContext.Consumer>
    );
};

export default MenuDrawerDesign;
