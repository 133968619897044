import * as React from "react";

import SectionTitle from "../elements/section-title";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
const TyreSection = () => {
    const data = useStaticQuery(graphql`
        query TyreSectionImage {
            file(relativePath: { eq: "design-page/tyre-image.jpg" }) {
                id
                childImageSharp {
                    fluid(
                        srcSetBreakpoints: [800, 1200]
                        sizes: "(min-width: 768px) 1200px, 800px"
                    ) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    `);
    return (
        <div className="guttered-container">
            <div className="container-fluid">
                {/* Spacer row */}
                <div className="row">
                    <div className="col-6 col-md-3 tb-border-start">
                        <div className="py-4"></div>
                        <div className="py-2 d-md-none"></div>
                        <div className="py-1 pb-0 d-md-none"></div>
                        <div className="py-5 d-none d-md-block"></div>
                        <div className="py-1 d-none d-md-block"></div>
                    </div>
                    <div className="col-6 col-md-3 tb-border-start tb-border-end tb-border-end-md-0"></div>
                    <div className="col-6 col-md-3 d-none d-md-block tb-border-start"></div>
                    <div className="col-6 col-md-3 d-none d-md-block tb-border-start tb-border-end"></div>
                </div>

                {/* Row 2 */}
                <div className="row">
                    <div className="col-md-3 d-none d-md-block tb-border-start"></div>
                    <div className="col-md-3 d-none d-md-block tb-border-start"></div>

                    <div className="col-6 col-md-3 tb-border-start">
                        <div className="flex-content-box">
                            <SectionTitle color="#d1c5b4">TYRE</SectionTitle>

                            <div className="description-container js-reveal">
                                <div className="py-4 pb-0 d-md-none"></div>
                                <div className="py-3 d-none d-md-block"></div>
                                <p className="tb-font-notosans-light">
                                    クラシックな見た目のブラック×アイボリータイヤ。
                                    細身でコンパクトな26インチタイヤを採用しているので、
                                    信号や坂道なども軽く快適にこぎ出すことが可能です。
                                </p>
                            </div>
                            <div className="py-3 d-none d-md-block"></div>
                            <div className="py-4 pb-0 d-md-none"></div>
                            <div className="py-1 pb-0 d-md-none"></div>
                        </div>
                    </div>
                    <div className="col-6 d-md-none tb-border-start tb-border-end"></div>
                    <div className="col-md-3 d-none d-md-block tb-border-start tb-border-end"></div>

                    <div className="col-md-3 d-none d-md-block tb-border-start"></div>
                    <div className="col-md-3 d-none d-md-block tb-border-start"></div>

                    <div className="col-12 col-md-6 tb-border-start tb-border-top tb-border-end tb-border-bottom">
                        <div className="image-wrapper column-height">
                            <Img
                                className="js-reveal-image"
                                fluid={data.file.childImageSharp.fluid}
                            />
                        </div>
                    </div>
                </div>

                {/* Spacer row */}
                <div className="row d-none d-md-flex">
                    <div className="col-6 col-md-3 tb-border-start">
                        <div className="py-5 d-none d-md-block"></div>
                        <div className="py-2 d-none d-md-block"></div>
                        <div className="py-1 d-none d-md-block"></div>
                    </div>
                    <div className="col-6 col-md-3 d-none d-md-block tb-border-start"></div>
                    <div className="col-6 col-md-3 d-none d-md-block tb-border-start"></div>
                    <div className="col-6 col-md-3 tb-border-start tb-border-end"></div>
                </div>
            </div>
        </div>
    );
};

export default TyreSection;
