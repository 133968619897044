import * as React from "react";
import { Link } from "gatsby";
import logo from "../../images/misc/logo.svg";
import FeaturedNavLink from "../elements/featured-nav-link";
import ScrollIcon from "../elements/scroll-icon";

const IntroSectionDesign = () => {
    return (
        <div className="guttered-container overflow-x-hidden">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-3 tb-border-start intro-logo-column">
                        <div className="logo-wrap">
                            <Link to="https://tokyobike.com">
                                <img
                                    src={logo}
                                    height="909"
                                    width="4637"
                                    alt="Tokyobike"
                                />
                            </Link>
                        </div>

                        <div className="scroll-down-button-wrap d-none d-md-block">
                            <a href="#frame" className="scroll-to-next">
                                <span className="scroll-text">Scroll Down</span>

                                <ScrollIcon styleName="scroll-down" />
                            </a>
                        </div>

                        <FeaturedNavLink
                            hash="frame"
                            title="Frame"
                            color="#e5b2a1"
                        >
                            FRAME
                        </FeaturedNavLink>
                    </div>
                    <div className="col-3 tb-border-start-0 tb-border-start-md">
                        <FeaturedNavLink
                            hash="handlebar"
                            title="Handlebar"
                            color="#92c7bc"
                        >
                            HANDLEBAR
                        </FeaturedNavLink>
                    </div>
                    <div className="col-3 tb-border-start">
                        <FeaturedNavLink
                            hash="tyre"
                            title="Tyre"
                            color="#d1c5b4"
                        >
                            TYRE
                        </FeaturedNavLink>
                    </div>
                    <div className="col-3 tb-border-start-0 tb-border-start-md tb-border-end position-relative">
                        <FeaturedNavLink
                            hash="gear-set"
                            title="Gear Set"
                            color="#414f2c"
                        >
                            GEAR SET
                        </FeaturedNavLink>
                        <FeaturedNavLink
                            hash="center-stand"
                            title="Center Stand"
                            halign="right"
                            color="#48637e"
                        >
                            CENTER STAND
                        </FeaturedNavLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IntroSectionDesign;
