import * as React from "react";

import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Autoplay } from "swiper";

SwiperCore.use([EffectFade, Autoplay]);

const IntroSwiperDesign = () => {
    const data = useStaticQuery(graphql`
        query DesignIntroSwiperImages {
            allFile(
                filter: { relativePath: { regex: "/design-top-slides/" } }
                sort: { fields: name }
            ) {
                nodes {
                    childImageSharp {
                        fluid(sizes: "110vw") {
                            originalName
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                    }
                }
            }
        }
    `);

    const swiperParams = {
        effect: "fade",
        fadeEffect: {
            crossFade: true,
        },
        speed: 1000,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
    };
    return (
        <div className="intro-swiper-container">
            <Swiper {...swiperParams}>
                {data.allFile.nodes.map((o, i) => {
                    return (
                        <SwiperSlide key={i}>
                            <div className="slide-image-wrap">
                                <Img fluid={o.childImageSharp.fluid} />
                            </div>
                        </SwiperSlide>
                    );
                })}
                {/* <SwiperSlide>
                    <div className="slide-image-wrap">
                        <img
                            src="https://source.unsplash.com/miUC0b1IVYU"
                            alt="Slide 1"
                        />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slide-image-wrap">
                        <img
                            src="https://source.unsplash.com/6tK2Og9dEKA"
                            alt="Slide 2"
                        />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slide-image-wrap">
                        <img
                            src="https://source.unsplash.com/7YUW7fvIYoQ"
                            alt="Slide 3"
                        />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slide-image-wrap">
                        <img
                            src="https://source.unsplash.com/nCj0zBLIaAk"
                            alt="Slide 4"
                        />
                    </div>
                </SwiperSlide> */}
                <div slot="container-start" className="container-start">
                    <div className="guttered-container">
                        <div className="container-fluid">
                            <div className="row tb-border-bottom tb-border-bottom-md-0">
                                <div className="col-6 col-sm-6 col-md-3 tb-border-start"></div>
                                <div className="col-md-3 d-none d-md-block tb-border-start"></div>
                                <div className="col-3 tb-border-start"></div>
                                <div className="col-3 tb-border-start tb-border-bottom-md tb-border-end">
                                    <div className="slide-caption text-right vertical-gutter">
                                        DESIGN
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Swiper>
        </div>
    );
};
export default IntroSwiperDesign;
