import * as React from "react";

import ScrollIcon from "../elements/scroll-icon";

const FooterSectionDesign = () => {
    return (
        <div className="guttered-container">
            <div className="container-fluid">
                {/* Spacer Row */}
                <div className="row">
                    <div className="col-12">
                        <div className="py-4"></div>
                        <div className="py-4 pb-0 d-md-none"></div>
                        <div className="py-1 pb-0 d-md-none"></div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 text-center">
                        <a href="#intro" className="scroll-to-top">
                            <ScrollIcon styleName="footer-scroll-icon" />

                            <span className="scroll-text">Back to top</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterSectionDesign;
