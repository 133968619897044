import * as React from "react";
import { Helmet } from "react-helmet";
import ToggleMenuButton from "../components/elements/toggle-menu-button";
import FrameSection from "../components/sections/frame-section";
import IntroSectionDesign from "../components/sections/intro-section-design";
import MenuDrawerDesign from "../components/sections/menu-drawer-design";
import IntroSwiperDesign from "../components/swipers/intro-swiper-design";

import { useGsapReveal } from "../components/animation/useGsapReveal";
import HandlebarSection from "../components/sections/handlebar-section";
import TyreSection from "../components/sections/tyre-section";
import GearSetSection from "../components/sections/gear-set-section";
import CenterStandSection from "../components/sections/center-stand-section";
import FooterSectionDesign from "../components/sections/footer-section-design";
import favicon from "../images/favicon.png";
const DesignPage = () => {
    useGsapReveal();
    return (
        <main>
            <Helmet>
                <meta charSet="utf-8" />
                <title>TOKYOBIKE LEGER 特設サイト - DESIGN</title>
                <link rel="canonical" href="https://tokyobike.com" />
                <meta
                    name="description"
                    content="気持ちのいい道具“ってなんだろう。
                    当たり前のようにそばにあって、気がねなく使えるもの。
                    シンプルだけど、さりげない心づかいを感じるもの。
                    いろいろ試してみたけれど、ふたたび手にとってしまうもの
                    TOKYOBIKE LEGER(レジェ)は、そんな気持ちのよさを形にしました。"
                />
                <link rel="icon" href={favicon} />
            </Helmet>
            <MenuDrawerDesign />
            <section className="section" id="intro">
                <ToggleMenuButton styleName="d-md-none" />

                <IntroSwiperDesign />
                <IntroSectionDesign />
            </section>
            <section className="section stick-toggle-menu" id="frame">
                <ToggleMenuButton styleName="sticky-button d-none d-md-block" />
                <FrameSection />
            </section>
            <section className="section" id="handlebar">
                <HandlebarSection />
            </section>
            <section className="section" id="tyre">
                <TyreSection />
            </section>
            <section className="section" id="gear-set">
                <GearSetSection />
            </section>
            <section className="section" id="center-stand">
                <CenterStandSection />
            </section>
            <section className="section" id="footer">
                <FooterSectionDesign />
            </section>
        </main>
    );
};

export default DesignPage;
